<template>
  <v-container fluid class="pa-0">
    <v-card class="mx-auto elevation-0" :width="contentWidth" outlined>
      <v-data-table
        dense
        item-key="name"
        height="calc(90vh - 250px)"
        hide-default-footer
        fixed-header
      >
        <template #top>
          <v-card-title>informações</v-card-title>

          <v-row no-gutters class="ma-0 pa-0">
            <v-col class="pa-0 ma-0" cols="12" lg="4" xl="3">
              <v-tabs
                v-model="tab"
                :grow="$vuetify.breakpoint.xs"
                class="mb-3 fixed-element"
                show-arrows
              >
                <v-tab
                  v-for="(item, index) in tabs"
                  :key="`${item}-${index}`"
                  class="d-flex justify-start pl-4 text-none text-body-1 grey--text"
                  active-class="font-weight-medium text--darken-2"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </template>
        <template #body>
          <v-tabs-items v-model="tab" class="px-6">
            <v-tab-item>
              <v-card-subtitle class="px-2">
                <v-row>
                  <v-col class="d-block" cols="12" md="9">
                    As informações são responsáveis por personalizar a
                    assinatura de e-mail do usuário. Saiba mais em
                    <a
                      class="accent--text"
                      target="_blank"
                      href="https://conectasuite.com/perguntas-frequentes/conecta-sign/como-usar-tags-na-assinatura-de-e-mail/"
                      >como usar Informações na assinatura de e-mail.</a
                    >
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-play-video-button
                      justify_btn="end"
                      title="Como usar Informações na sua assinatura de e-mail."
                      id_youtube_video="6uAXHh60D-M"
                    />
                  </v-col>
                </v-row>
              </v-card-subtitle>

              <v-card-title class="px-2">
                Informações gerais da empresa
              </v-card-title>

              <!-- Tags NÃO editáveis -->
              <v-row v-for="tag in disabledCompanyTagsNames" :key="tag">
                <v-col cols="12" sm="5" lg="4" align-self="center">
                  <v-card-text class="font-weight-medium body-2 py-0 pl-2">
                    {{ disabledCompanyTags[tag].keyName }}
                    <v-tooltip bottom v-if="disabledCompanyTags[tag].info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="info"
                          small
                          dark
                          v-bind="attrs"
                          v-on="on"
                          v-text="'mdi-help-circle-outline'"
                        />
                      </template>
                      <span>{{ disabledCompanyTags[tag].info }}</span>
                    </v-tooltip>
                  </v-card-text>
                  <v-card-text
                    class="grey--text text--darken-2 body-2 py-0 pl-2"
                    >{{ disabledCompanyTags[tag].label }}
                  </v-card-text>
                </v-col>
                <v-col
                  class="text-left"
                  align-self="start"
                  cols="12"
                  sm="7"
                  lg="8"
                >
                  <v-card-text class="body-2 pa-0">
                    <v-text-field
                      color="grey"
                      v-model="tagsEdit[tag]"
                      class="pt-0 pr-2 my-0 body-2"
                      disabled
                      hide-details
                    />
                  </v-card-text>
                </v-col>
              </v-row>
              <!-- Tags editáveis -->
              <div v-for="tag in editableCompanyTagsNames" :key="tag">
                <v-card-title class="px-2 mt-6" v-if="tag === 'phone'">
                  Informações de contato
                </v-card-title>
                <v-card-title
                  class="px-2 mt-6"
                  v-if="tag === 'social_media_facebook'"
                >
                  Redes sociais
                </v-card-title>
                <v-card-title class="px-2 mt-6" v-if="tag === 'slogan'">
                  Mais informações
                </v-card-title>
                <v-card-title
                  class="px-2 mt-6"
                  v-if="tag === 'company_custom_1'"
                >
                  <span>informações personalizadas</span>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="tagLimitExceeded && on">
                        <v-btn
                          :loading="tagLoading"
                          :disabled="tagLoading || tagLimitExceeded"
                          small
                          color="accent"
                          class="ma-2 white--text text-none"
                          @click="showNewTagDialog = true"
                        >
                          <v-icon left dark> mdi-plus </v-icon>
                          Adicionar nova tag
                        </v-btn>
                      </div>
                    </template>
                    <span
                      >O limite de informações personalizadas foi excedido</span
                    >
                  </v-tooltip>
                </v-card-title>
                <Alert v-if="tag === 'phone'" class="mx-1" dense>
                  <span class="font-weight-bold mr-1">Dica:</span> no campo de
                  telefone, insira números de telefones completos contendo seu
                  DDI, DDD e número. Exemplo: +551112345678.
                </Alert>
                <v-row>
                  <v-col
                    cols="12"
                    sm="5"
                    lg="4"
                    align-self="center"
                    class="mt-4"
                  >
                    <h3 v-if="tag.header" class="mx-2 my-8 font-weight-medium">
                      {{ tag.header }}
                    </h3>
                    <v-card-text class="font-weight-medium body-2 py-0 pl-2"
                      >{{ editableCompanyTags[tag].keyName }}
                      <v-tooltip bottom v-if="editableCompanyTags[tag].info">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="editableCompanyTags[tag].goToProfile"
                            @click="goToCompanyProfile()"
                            color="info"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            v-text="'mdi-help-circle-outline'"
                          />
                          <v-icon
                            v-else
                            color="info"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            v-text="'mdi-help-circle-outline'"
                          />
                        </template>
                        <span>{{ editableCompanyTags[tag].info }}</span>
                      </v-tooltip>
                    </v-card-text>
                    <v-card-text
                      class="grey--text text--darken-2 body-2 py-0 pl-2"
                      >{{ editableCompanyTags[tag].label }}</v-card-text
                    >
                  </v-col>

                  <v-col
                    class="text-right mt-4"
                    align-self="end"
                    cols="12"
                    sm="7"
                    lg="8"
                  >
                    <v-text-field
                      color="accent"
                      v-model="tagsEdit[tag]"
                      class="pt-0 pr-2 my-0 body-2"
                      autocomplete="off"
                      v-if="editableCompanyTags[tag].hasPhoneMask"
                      :rules="[
                        (v) =>
                          v
                            ? v.length <= 20 || 'Máximo de 20 caracteres'
                            : true,
                      ]"
                      :disabled="editableCompanyTags[tag].disabled"
                      hint="Insira
                    um número de telefone preferencialmente com o DDI, DDD e
                    número"
                    />
                    <v-form
                      ref="uosTagsForm"
                      lazy-validation
                      onsubmit="return false;"
                      v-else
                    >
                      <div
                        v-if="
                          editableCompanyTags[tag].label ===
                          '[*COMPANY_LOGO_URL*]'
                        "
                      >
                        <v-img
                          v-if="tagsEdit[tag]"
                          max-height="150"
                          max-width="250"
                          class="mx-auto my-4"
                          :src="companyLogo"
                        ></v-img>
                        <v-text-field
                          color="grey"
                          @input="updateURL(tag)"
                          v-model="tagsEdit[tag]"
                          hint="Para evitar problemas com a imagem da sua logo, é recomendado que a 
                        imagem seja uma url compartilhada com todos no Google Drive.
                        Você também pode utilizar imagens hospedadas pelo seu próprio servidor."
                          class="pt-0 pr-2 my-0 body-2"
                          clearable
                        />
                      </div>
                      <div
                        v-else-if="
                          editableCompanyTags[tag].label ===
                          '[*COMPANY_BANNER_URL*]'
                        "
                      >
                        <a :href="companyBannerLink" target="_blank">
                          <v-img
                            v-if="tagsEdit[tag]"
                            max-width="600"
                            width="100%"
                            class="mx-auto my-4"
                            :src="companyBanner"
                          ></v-img>
                        </a>
                        <v-text-field
                          color="grey"
                          @input="updateURL(tag)"
                          v-model="tagsEdit[tag]"
                          hint="Para evitar problemas com a imagem do seu banner, é recomendado que a 
                        imagem seja uma url compartilhada com todos no Google Drive.
                        Você também pode utilizar imagens hospedadas pelo seu próprio servidor."
                          class="pt-0 pr-2 my-0 body-2"
                          clearable
                        />
                      </div>
                      <v-text-field
                        v-else
                        color="grey"
                        v-model="tagsEdit[tag]"
                        :disabled="editableCompanyTags[tag].disabled"
                        :type="editableCompanyTags[tag].type"
                        :placeholder="editableCompanyTags[tag].placeholder"
                        class="pt-0 pr-2 my-0 body-2"
                        :rules="tagRules"
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </div>
              <v-row v-for="tag in customTagsEdit" :key="tag.name" class="mt-4">
                <v-col
                  cols="12"
                  sm="5"
                  lg="4"
                  align-self="end"
                  class="px-0 mx-0 d-flex align-end"
                >
                  <div>
                    <v-card-text class="font-weight-medium body-2 py-0 ml-1"
                      >{{ tag.name }}
                    </v-card-text>
                    <v-card-text
                      class="grey--text text--darken-2 body-2 py-0 ml-1"
                      >{{ tag.tag }}</v-card-text
                    >
                  </div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="grey"
                        class="mr-5 no-background-hover"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          tagNameSelected = tag.name;
                          deleteTagDialog = true;
                        "
                      >
                        <v-icon class="mt-3">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="7" lg="8" align-self="end">
                  <v-text-field
                    :placeholder="tag.name"
                    v-model="tag.value"
                    color="grey"
                    class="pt-0 my-0 body-2"
                    :rules="tagRules"
                  />
                </v-col>
              </v-row>
              <div class="mb-6" />
            </v-tab-item>
            <v-tab-item>
              <div class="uk-container uk-padding ml-1">
                <div class="uk-timeline">
                  <Alert color="info" dense class="mb-6">
                    Para entender mais sobre edição de Informações utilizando
                    CSV no Conecta Suite,
                    <a
                      href="https://conectasuite.com/perguntas-frequentes/como-editar-tags-no-csv/"
                      >consulte o nosso FAQ.</a
                    >
                  </Alert>
                  <div class="uk-timeline-item">
                    <div class="uk-timeline-icon">
                      <span class="uk-badge">1</span>
                    </div>
                    <div class="uk-timeline-content">
                      <div
                        class="uk-card uk-card-default uk-margin-medium-bottom"
                      >
                        <div class="uk-card-header">
                          <div class="uk-grid-small uk-flex-middle" uk-grid>
                            <div class="text-body-1 font-weight-bold">
                              Fazer o download do arquivo CSV
                            </div>
                          </div>
                        </div>
                        <div class="uk-card-body">
                          <p class="px-0 my-4">
                            <v-btn
                              color="background"
                              elevation="3"
                              class="primary--text mx-0"
                              @click="handleGetUsers"
                              :loading="loadingUsers"
                              :disabled="loadingUsers"
                            >
                              <v-icon left v-text="'mdi-download'" />
                              Baixar informações dos usuários
                            </v-btn>
                            <download-csv
                              :data="users"
                              :labels="userLabels"
                              :fields="userFields"
                              :file-name="`Usuários - ${companyName}`"
                            >
                              <div id="buttondownloadCsv"></div>
                            </download-csv>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-timeline-item mt-6">
                    <div class="uk-timeline-icon">
                      <span class="uk-badge">2</span>
                    </div>
                    <div class="uk-timeline-content">
                      <div
                        class="uk-card uk-card-default uk-margin-medium-bottom"
                      >
                        <div class="uk-card-header">
                          <div class="uk-grid-small uk-flex-middle" uk-grid>
                            <div class="text-body-1 font-weight-bold">
                              Adicionar ou editar informações do usuário no
                              modelo CSV.
                              <!-- <a
                          class="primary--text text-decoration-none"
                          href=""
                          target="_blank"
                          >Saiba mais sobre os modelos de CSV</a
                        > -->
                            </div>
                            <span class="uk-margin-auto-left text-body-2"
                              >Os campos obrigatórios são os nomes (primeiro e
                              último) e endereço de e-mail.</span
                            >
                          </div>
                        </div>
                        <div class="uk-card-body pt-4">
                          <v-card class="transparent py-2 elevation-0" tile>
                            <table class="text-body-2">
                              <thead class="grey lighten-2 border-0">
                                <tr>
                                  <th class="text-left pa-2">Primeiro nome</th>
                                  <th class="text-left pa-2">Último nome</th>
                                  <th class="text-left pa-2">E-mail</th>
                                  <th class="text-left pa-2">...</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="">
                                  <td class="text-left pa-2 outlined">Nome</td>
                                  <td class="text-left pa-2 outlined">
                                    Sobrenome
                                  </td>
                                  <td class="text-left pa-2 outlined">
                                    usuario@mail.com
                                  </td>
                                  <td class="text-left pa-2 outlined">...</td>
                                </tr>
                              </tbody>
                            </table>

                            <Alert color="info" dense class="mt-5">
                              As informações definidas como obrigatórias não
                              serão alteradas ao aplicar.
                            </Alert>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-timeline-item mt-6">
                    <div class="uk-timeline-icon">
                      <span class="uk-badge">3</span>
                    </div>
                    <div class="uk-timeline-content">
                      <div>
                        <div class="uk-card-header">
                          <div class="uk-grid-small uk-flex-middle">
                            <div class="text-body-1 font-weight-bold">
                              Fazer o upload do arquivo CSV
                            </div>
                          </div>
                        </div>
                        <div class="uk-card-body pt-4">
                          <v-card flat tile :style="styles">
                            <Alert
                              v-if="hasCsvError"
                              color="error"
                              dense
                              class="mt-2"
                            >
                              <span class="font-weight-medium">{{
                                csvUploadError
                              }}</span>
                            </Alert>

                            <Alert
                              v-if="hasManyUsers"
                              color="warning"
                              dense
                              class="mt-2"
                            >
                              <span class="font-weight-medium">
                                Você possui {{ usersNumber }} usuários, a
                                atualização em massa pode levar alguns minutos
                                até serem efetivadas.
                              </span>
                            </Alert>

                            <upload-csv
                              @content="setContent"
                              @error="setError"
                              color="background"
                              elevation="3"
                              custom-class="primary--text my-2"
                              text="Anexar arquivo CSV"
                            />

                            <v-card-subtitle
                              class="px-0 mt-6"
                              v-if="results.length > 0"
                            >
                              Verifique se as informações estão corretas antes
                              de confirmar.
                            </v-card-subtitle>

                            <v-card
                              outlined
                              class="overflow-hidden mt-2"
                              width="100%"
                              v-if="results.length > 0"
                            >
                              <v-card-subtitle
                                class="px-4 py-2 font-weight-medium text-body-2"
                              >
                                Pré-visualização das informações contidas no CSV
                              </v-card-subtitle>

                              <v-divider />

                              <v-data-table
                                :headers="fileHeaders"
                                :items="results"
                                :items-per-page="-1"
                                fixed-header
                                hide-default-footer
                                height="250px"
                                class="elevation-0 overflow-auto"
                                dense
                                calculate-widths
                              >
                                <template v-slot:[`item.sign_photo`]="{ item }">
                                  <v-avatar v-if="item.sign_photo" size="24">
                                    <img
                                      :src="item.sign_photo"
                                      :alt="`${item.first_name} ${item.last_name}`"
                                    />
                                  </v-avatar>
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template #footer>
          <v-footer
            rounded
            style="height: 70px"
            color="background"
            class="border-bottom rounded-xl"
          >
            <div v-if="tab === 0">
              <v-btn
                text
                color="grey"
                class="text-none text-body-1 font-weight-medium"
                large
                @click="resetFields()"
                :disabled="tagLoading || loadingSavingData"
              >
                Limpar campos
              </v-btn>
              <v-menu
                open-on-hover
                bottom
                left
                offset-overflow
                offset-y
                class="mx-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    class="px-6 mx-10 white--text text-none text-body-1 font-weight-medium"
                    large
                    :loading="loadingSavingData"
                    :disabled="tagLoading || canNotSave || tagLengthError"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'mdi-content-save'" left />
                    Salvar alterações
                  </v-btn>
                </template>
                <v-list nav class="font-weight-medium" width="400">
                  <v-list-item @click.capture="showSimple = true">
                    <v-list-item-icon class="mr-4">
                      <v-icon
                        v-text="'mdi-check-circle-outline'"
                        color="accent"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Apenas salvar</v-list-item-title>
                      <v-list-item-subtitle
                        class="text-wrap font-weight-regular mt-1 caption"
                      >
                        Salvar as alterações das informações sem reaplicar as
                        assinaturas
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="saveAndReapplyTags">
                    <v-list-item-icon class="mr-4">
                      <v-icon v-text="'mdi-update'" color="accent" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Salvar e reaplicar</v-list-item-title>
                      <v-list-item-subtitle
                        class="text-wrap font-weight-regular mt-1 caption"
                      >
                        Salvar as alterações e reaplicar assinaturas com a
                        infomação atualizada
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <v-btn
                text
                color="grey"
                :disabled="!results.length || loading"
                @click="resetData"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="accent"
                class="px-4 white--text"
                :loading="loading"
                @click="saveUsers"
                :disabled="!results.length || loading"
              >
                Salvar
              </v-btn>
            </div>
          </v-footer>
        </template>
      </v-data-table>
    </v-card>
    <ConfirmationDialog
      :show="deleteTagDialog"
      action-text="Excluir tag personalizada"
      :loading="tagLoading"
      width="auto"
      @close="closeTagDialog"
      @ok="deleteCustomTag"
      :reversible="false"
      dense
      :message="`Deseja confirmar a exclusão da tag
        <strong>${tagNameSelected}</strong>?`"
    />
    <HeaderDialog
      id="custom-tag-dialog"
      :title="$t('userInformations.newCustomTag')"
      color="primary"
      :show="showNewTagDialog"
      :loading="tagLoading"
      width="700"
      :disabledAction="disableCustomTagDialog"
      showCancel
      actionText="Confirmar"
      closeText="cancelar"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeTagDialog"
      @action="addNewCustomTag"
    >
      <template v-slot:body v-if="showNewTagDialog">
        <v-row :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex flex-column'">
          <v-col>
            <v-text-field
              :rules="rules"
              label="Nome"
              v-model="handleNewCustomTagName"
              @update:error="tagError = $event"
              :error-messages="tagErroMessages"
            >
              <template v-slot:message="{ message }">{{ message }}</template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              disabled
              label="Representação"
              :placeholder="customTag"
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
    <HeaderDialog
      id="reapply-model"
      title="Deseja reaplicar os modelos de assinatura com os novos valores das informações?"
      color="primary"
      :show="showReapplyDialog"
      :loading="reapplyLoading"
      width="700"
      :disabledAction="reapplyLoading"
      showCancel
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      :actionText="
        impactedTemplates.length ? 'Salvar e reaplicar' : 'Salvar alterações'
      "
      closeText="Cancelar"
      @close="showReapplyDialog = false"
      @action="reapplyModels"
    >
      <template v-slot:body v-if="impactedTemplates.length">
        <p>As alterações serão aplicadas nos modelos listados abaixo:</p>
        <p>
          <small>
            <b>Dica:</b> Você também pode gerenciar quais modelos devem ser
            reaplicados incluindo ou removendo-os do campo abaixo:
          </small>
        </p>
        <v-autocomplete
          v-model="templatesToReapply"
          :items="impactedTemplates"
          label="Modelos afetados"
          multiple
          chips
          outlined
          class="mt-5"
          color="accent"
          dense
          item-value="id"
        >
          <template v-slot:selection="{ attrs, item, selected, parent }">
            <v-chip
              v-bind="attrs"
              :color="`${item.color} lighten-3`"
              class="ma-1"
              small
              :input-value="selected"
            >
              <span class="pr-2">
                {{ item.name }}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
                v-text="'mdi-close-circle'"
              />
            </v-chip>
          </template>

          <template v-slot:item="{ active, item, attrs, on }">
            <template>
              <v-list-item
                v-on="on"
                v-bind="attrs"
                :color="active ? 'accent lighten-3' : 'white'"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-autocomplete>
        <Alert :prominent="false" color="warning" dense :width="800">
          Os modelos de assinatura podem levar até 10 minutos para serem
          efetivamente aplicados.
        </Alert>
      </template>
      <template v-slot:body v-else>
        <Alert :prominent="false" color="warning" dense :width="800">
          Não há modelos afetados pelas informações alteradas.
        </Alert>
      </template>
    </HeaderDialog>
    <SimpleDialog
      :show="showSimple"
      title="Confirmar alterações das informações?"
      :customButtom="true"
      outlined
      color="primary"
      button-class="white--text"
      dense
      showCancel
      :showLoading="loadingSavingData"
      :disabledAction="loadingSavingData"
      closeText="Cancelar"
      @close="showSimple = false"
      @action="saveOrgUnitTags()"
    >
    </SimpleDialog>
    <!-- MODAL DE FEEDBACK -->
    <HeaderDialog
      v-if="!currentUser.feedbacks.edit_company_tags"
      ref="form"
      id="user_feedback"
      title="Deixe seu feedback sobre a funcionalidade"
      :show="showUserFeedback"
      width="600"
      :persistent="false"
      custom-footer
      @close="closeFeedback"
    >
      <template v-slot:body>
        <v-row class="mx-0">
          <v-col class="mx-0">
            <FeedbackRating
              action="edit_company_tags"
              showDismiss
              @close="closeFeedback"
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import {
  companyTags,
  disabledCompanyTags,
  editableCompanyTags,
} from "@//helpers/variables/tags";
import { changeDriveURL } from "@/helpers/services/images";
import { impactedTemplateIds } from "@/helpers/services/utils";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  userFields,
  userFileHeaders,
  userLabels,
} from "@/helpers/variables/files";
import Upload from "@/components/main/shared/Upload.vue";
import JsonCSV from "@/components/main/shared/download.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import FeedbackRating from "@/components/forms/FeedbackRating.vue";

export default {
  name: "EditTags",
  directives: { mask },
  components: {
    "download-csv": JsonCSV,
    "upload-csv": Upload,
    ConfirmationDialog,
    FeedbackRating,
  },
  data() {
    return {
      results: [],
      userFields,
      userLabels,
      errorMessages,
      csvUploadError: false,
      loading: false,
      tagsEdit: {},
      editableCompanyTags,
      disabledCompanyTags,
      companyTags,
      isValidPhone: true,
      loadingSavingData: false,
      modifiedTags: [],
      showReapplyDialog: false,
      impactedTemplates: [],
      reapplyLoading: false,
      templatesToReapply: [],
      showSimple: false,
      tab: null,
      tabs: ["informações da empresa", "informações dos usuários"],
      showNewTagDialog: false,
      tagLoading: false,
      customTagName: "",
      tagRules: [
        (v) =>
          String(v).length <= 1490 ||
          "O valor não pode ter mais que 1490 caracteres",
      ],
      tagError: false,
      tagErroMessages: [],
      customTagsEdit: {},
      tagNameSelected: "",
      deleteTagDialog: false,
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          /^[a-zA-Z0-9\s]+$/.test(v) ||
          "O campo não pode conter caracteres especiais",
        (v) => v.length <= 25 || "O campo tem que ser menor que 25",
        (v) => v.trim().length > 0 || "O campo não pode conter somente espaços",
      ],
      showUserFeedback: false,
      loadingUsers: false,
    };
  },

  beforeMount() {
    this.getCompanyInfo();
    this.getSignatureTemplates();
  },

  computed: {
    ...mapGetters([
      "token",
      "company",
      "contentWidth",
      "usersNumber",
      "users",
      "currentUser",
      "usersCustomTagKeys",
      "usersCustomTags",
      "allTemplates",
    ]),

    fileHeaders() {
      const customTagsHeaders = this.usersCustomTagKeys.map((tagKey) => {
        const customTag = this.usersCustomTags[tagKey];
        return {
          text: customTag.name,
          value: `custom_tags.${tagKey}.value`,
          sortable: false,
          width: 150,
        };
      });

      return [...userFileHeaders, ...customTagsHeaders];
    },

    tagLimitExceeded() {
      return Object.keys(this.company.custom_tags).length >= 5;
    },

    tagLengthError() {
      const customTagsError = Object.values(this.customTagsEdit).some(
        (tag) => tag.value.length > 1490
      );

      if (customTagsError) {
        return customTagsError;
      }

      const tagsError = Object.values(this.tagsEdit).some(
        (tag) => String(tag).length > 1490
      );

      return tagsError;
    },

    disableCustomTagDialog() {
      return (
        this.tagError ||
        !this.customTagName ||
        this.customTagName === this.tagNameSelected
      );
    },

    customTagKey() {
      return this.tagNameSelected.toUpperCase().replace(/ /g, "_");
    },

    customTag() {
      return `[*${this.customTagName}*]`.toUpperCase().replace(/ /g, "_");
    },

    handleNewCustomTagName: {
      get() {
        return this.customTagName;
      },
      set(name) {
        if (this.tagErroMessages) {
          this.tagErroMessages = [];
        }

        this.customTagName = name;
      },
    },

    hasManyUsers() {
      return this.usersNumber.length >= 500;
    },

    companyName() {
      return this.company.name;
    },

    hasCsvError() {
      return this.csvUploadError || false;
    },
    styles() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "max-width: 400px";
      }
      return `max-width: ${
        this.$vuetify.breakpoint.lgAndDown ? "900px" : "1200px"
      }`;
    },

    canNotSave() {
      const payload = this.createPayload();

      return Object.keys(payload).length === 0 || !this.isValidPhone;
    },

    companyLogo() {
      return this.tagsEdit.logo_url;
    },

    companyBanner() {
      return this.tagsEdit.banner_url;
    },

    companyBannerLink() {
      return this.tagsEdit.banner_link;
    },

    disabledCompanyTagsNames() {
      return Object.keys(this.disabledCompanyTags);
    },

    editableCompanyTagsNames() {
      return Object.keys(this.editableCompanyTags);
    },
  },

  methods: {
    ...mapActions([
      "saveUsersTags",
      "completeMission",
      "getUsers",
      "getSignatureTemplates",
    ]),
    ...mapMutations(["setSnackBar", "setCompany"]),

    async handleGetUsers() {
      this.loadingUsers = true;
      await this.getUsers();
      document.getElementById("buttondownloadCsv").click();
      this.loadingUsers = false;
    },

    deleteCustomTag() {
      this.tagLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/custom-tags?tag_key=${this.customTagKey}`;

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios
        .delete(url, auth)
        .then((response) => {
          this.setCompany(response.data);
          this.setSnackBar({
            message: successMessages.delete_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.updateTags();
          this.updateCustomTags();
          this.closeTagDialog();
          this.tagLoading = false;
        });
    },

    async addNewCustomTag() {
      this.tagLoading = true;

      const url = process.env.VUE_APP_API_BASE_URL + "/company/custom-tags";

      const payload = {
        name: this.customTagName,
        tag: this.customTag,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .post(url, payload, auth)
        .then((response) => {
          this.setCompany(response.data);
          this.updateTags();
          this.updateCustomTags();
          this.closeTagDialog();
          this.setSnackBar({
            message: successMessages.new_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            show: true,
            color: "error",
          });

          this.tagErroMessages = [errorMessages[data.code]];
        })
        .finally(() => {
          this.tagLoading = false;
        });
    },

    closeTagDialog() {
      this.tagError = false;
      this.customTagName = "";
      this.tagNameSelected = "";
      this.showNewTagDialog = false;
      this.deleteTagDialog = false;
      this.tagErroMessages = [];
    },

    async saveUsers() {
      this.loading = true;
      await this.saveUsersTags(this.results);
      this.loading = false;
      this.resetData();
    },

    resetData() {
      this.results = [];
      this.csvUploadError = false;
    },

    setContent(data) {
      this.results = Object.values(data);
      this.csvUploadError = false;
    },

    setError(messageKey) {
      this.results = [];
      this.csvUploadError = errorMessages[messageKey];
    },

    updateURL(tag) {
      this.tagsEdit[tag] = changeDriveURL(this.tagsEdit[tag]);
    },

    getCountryCodePhone(item) {
      if (item === "Telefone 2") {
        return "ddi_phone2";
      } else if (item === "Telefone 3") {
        return "ddi_phone3";
      } else if (item === "Celular") {
        return "ddi_cellphone";
      } else if (item === "WhatsApp") {
        return "ddi_whatsapp";
      } else {
        return "ddi";
      }
    },

    resetFields() {
      this.updateTags();
      this.updateCustomTags();
    },
    goToCompanyProfile() {
      this.$router.push({ path: "/company" });
    },
    async getCompanyInfo() {
      this.loading = true;
      const snackbarConfig = { show: true };
      if (this.company) {
        this.updateCustomTags();
        this.updateTags();
      } else {
        snackbarConfig.message = errorMessages.unknown;
        snackbarConfig.color = "error";
        this.setSnackBar(snackbarConfig);
      }

      this.loading = false;
    },

    updateCustomTags() {
      this.customTagsEdit = JSON.parse(
        JSON.stringify(this.company.custom_tags)
      );
    },

    updateTags() {
      this.tagsEdit = companyTags.reduce(
        (a, e) => (this.company[e] ? (a[e] = this.company[e]) : -1, a),
        {}
      );
    },

    async reapplyModels() {
      this.reapplyLoading = true;
      await this.saveOrgUnitTags(true);
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/conecta-sign/reapply-signature/batch:async";
      const payload = {
        templates: this.templatesToReapply,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const snackbarConfig = { show: true };
      await this.$axios
        .put(url, payload, auth)
        .then(() => {
          snackbarConfig.message = successMessages.reapply_signature_to_users;
          this.setSnackBar(snackbarConfig);
          this.reapplyLoading = false;
          this.showReapplyDialog = false;
        })
        .catch(() => {
          snackbarConfig.message = errorMessages.unknown;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
          this.reapplyLoading = false;
          this.showReapplyDialog = false;
        })
        .finally(() => {
          this.showUserFeedback = true;
        });
    },

    async saveAndReapplyTags() {
      this.impactedTemplates = impactedTemplateIds(
        this.allTemplates,
        this.modifiedTags
      );
      if (this.impactedTemplates) {
        this.templatesToReapply = this.impactedTemplates.map(({ id }) => id);
      }
      this.showReapplyDialog = true;
    },

    async saveOrgUnitTags(value = false) {
      this.loadingSavingData = true;
      let url = process.env.VUE_APP_API_BASE_URL + "/company/tags";
      let payload = this.createPayload();

      let auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const snackbarConfig = { show: true };
      await this.$axios
        .put(url, payload, auth)
        .then((response) => {
          snackbarConfig.message = successMessages.saves_changes;
          if (value === false) {
            this.setSnackBar(snackbarConfig);
          }
          this.setCompany(response.data);
          this.updateTags();
          this.completeMission("edit_company_tags");
          this.updateCustomTags();
          this.loadingSavingData = false;
          this.showSimple = false;
        })
        .catch((err) => {
          snackbarConfig.message = errorMessages.unknown;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
          console.error("saveOrgUnitTags(): ", err);
          this.loadingSavingData = false;
          this.showSimple = false;
        })
        .finally(() => {
          this.showUserFeedback = true;
        });
    },

    formatedURL(url) {
      if (url.toLowerCase().startsWith("http://")) {
        return url.replace("http://", "https://");
      } else if (url.toLowerCase().startsWith("https://")) {
        return url;
      } else if (url.toLowerCase().startsWith("www.")) {
        return `https://${url}`;
      } else if (url.toLowerCase().startsWith("ww.")) {
        return url.replace("ww.", "https://www.");
      } else if (!url) {
        return url;
      } else {
        return `https://www.${url}`;
      }
    },

    createPayload() {
      let payload = {};

      Object.keys(this.tagsEdit).forEach((tag) => {
        let tagLabel = editableCompanyTags[tag]?.label;

        if (this.tagsEdit[tag] != this.company[tag]) {
          if (tag.match(/social_media/) && tag != "social_media_whatsapp") {
            payload[tag] = this.formatedURL(this.tagsEdit[tag]);
          } else {
            payload[tag] = this.tagsEdit[tag];
          }

          if (tagLabel) {
            tagLabel = tagLabel.replace(/[*[\]]/g, "");
            this.modifiedTags.push(tagLabel);
          }
        } else if (tagLabel) {
          tagLabel = tagLabel.replace(/[*[\]]/g, "");

          this.modifiedTags = this.modifiedTags.filter(
            (value) => value !== tagLabel
          );
        }
      });

      const { custom_tags } = this.company;

      let customTagsEdited = false;

      Object.keys(this.customTagsEdit).forEach((tag) => {
        const tagLabel = this.customTagsEdit[tag].tag.replace(/[*[\]]/g, "");

        if (this.customTagsEdit[tag].value != custom_tags[tag].value) {
          if (!payload.custom_tags) {
            payload.custom_tags = {};
          }

          payload.custom_tags[tag] = this.customTagsEdit[tag];

          customTagsEdited = true;

          this.modifiedTags.push(tagLabel);
        } else {
          this.modifiedTags = this.modifiedTags.filter(
            (value) => value !== tagLabel
          );
        }
      });

      if (!customTagsEdited) {
        delete payload.custom_tags;
      }

      return payload;
    },
    closeFeedback() {
      this.showUserFeedback = false;
    },
  },
};
</script>
<style>
.v-tab {
  text-transform: none;
}

.fixed-element {
  position: sticky;
  top: 0;
  z-index: 4;
}

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  position: absolute;
  top: 24px;
  width: 1px;
}

.uk-timeline .uk-timeline-item:last-of-type::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  top: 24px;
  width: 0px;
}

.uk-badge {
  background: var(--v-primary-base);
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: bold;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  font-family: "Roboto";
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
  font-family: "Roboto";
}
</style>
